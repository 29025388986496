/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
// import { format, isValid, parse } from 'date-fns';
import React from 'react';

import {
  Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis,
} from 'recharts';
import { format, isValid, parse } from 'date-fns';
import { useMantineColorScheme } from '@mantine/core';
import { assetPrefix, getMonthInfo, renderNumber } from '../../../utilities/general';
import styles from './CompanyDataV2.module.css';
// import { InvestmentDummy } from './BalanceSheet/dummy';

// const CustomizedCursor = (x) => {
//   const { points, height } = x;
//   return (<line x1={points[0].x - 2} y1={116} x2={points[0].x - 2} y2={height}
//     stroke="#026DB08C" strokeWidth={1} strokeDasharray={3} />

//   );
// };
export default function LineCharts(props) {
  const {
    chartName, total, changeInPercentage, days, data,
    chartId,
  } = props;
  const { colorScheme } = useMantineColorScheme();
  // const [height, setHeight] = useState(null);

  const DateFormatter = (date) => {
    const d = days === 180 ? parse(date, 'M yyyy', new Date()) : parse(date, 'yyyy-MM-dd', new Date());
    return isValid(d) ? days === 'Last 3 Months' ? format(d, 'LLL') : format(d, 'LLL dd') : null;
  };
  const CustomTooltip = (propss) => {
    const { payload } = propss;
    const assetValue = payload.map((val) => val?.payload);
    return (
      <div className="flex p-1 h-16 w-30 bg-white border border-gray-400">
        {
          assetValue?.map((item, i) => (
            <div className="flex flex-col gap-1" key={i}>
              <div className="text-base font-normal">{getMonthInfo(item?.date)}</div>
              <div className="flex text-base font-normal justify-center">{`$${renderNumber(item?.value)}`}</div>
            </div>
          ))
        }
      </div>
    );
  };

  const values = data && data?.map((i) => i?.value);

  const gradientOffset = () => {
    const dataMax = values && Math.max(...values);
    const dataMin = values && Math.min(...values);

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }
    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();

  // const renderActiveDot = (propsss) => {
  //   const { cx, cy, value } = propsss;
  //   // setHeight(cy);
  //   return (
  //     <svg
  //       x={cx - 27}
  //       y={cy - 35}
  //       width="200"
  //       height="68"
  //       viewBox="0 0 200 68"
  //     >
  //       <svg xmlns="http://www.w3.org/2000/svg" width="53" height="27" viewBox="0 0 53 27" fill="none">
  //         <path d="M0.522461 6.03466C0.522461 2.72095 3.20875 0.034668 6.52246 0.0
  // 34668H46.3206C49.6344 0.034668 52.3206 2.72096 52.3206 6.03467V16.9779C52.3206 20.16
  // 16 49.7398 22.7425 46.5561 22.7425H34.7528C31.8751 22.7425
  //  29.1336 23.9684 27.215 26.1132C26.7918 2
  // 6.5863 26.0513 26.5863 25.6281 26.1132C23.7095 23.968
  // 4 20.968 22.7425 18.0903 22.7425H6.28701C3.10334 22.7425 0.522
  // 461 20.1616 0.522461 16.9779V6.03466Z" fill="#026DB0" />
  //       </svg>
  //       <text className={styles.tooltipText} textAnchor="middle" dy={15} dx={27}>
  //         {renderNumber(value, true)}
  //       </text>
  //       <rect
  //         x="21.3"
  //         y="30.4"
  //         width="8"
  //         height="8"
  //         rx="7"
  //         fill="#fff"
  //         stroke="blue"
  //         strokeWidth="2"
  //       />
  //     </svg>
  //   );
  // };
  return (
    <div className={`${styles.paddingLeft19} flex flex-col py-3 px-3 h-full`}>
      <div className="pt-0">
        <div className={`${styles.HeadingText} ${colorScheme === 'dark' ? 'text-[#beb9b9]' : 'text-[#3A3A3A]'} flex items-baseline gap-1`}>
          {`${chartName}`}
          <span className="text-xs opacity-70 flex !items-center">{` - ${days}`}</span>
        </div>
        <div className="flex gap-4 hidden">
          <span className={`text-2xl font-bold ${colorScheme === 'dark' ? 'text-[#beb9b9]' : 'text-[#3A3A3A]'}`}>{total ? renderNumber(total, true) : '$1,643'}</span>
          <div className="flex gap-2 items-center">
            <span>
              {changeInPercentage > 0
                ? (
                  <img
                    src={`${assetPrefix}/images/GreenArrow.svg`}
                    alt="High"
                    className="max-w-fit w-3 h-3"
                  />
                )
                : (
                  <img
                    src={`${assetPrefix}/images/RedArrow.svg`}
                    alt="Low"
                    className="max-w-fit w-3 h-3"
                  />
                )}
            </span>
            <span className={`${styles.changeInPercentage} ${changeInPercentage > 0 ? 'text-green-500' : 'text-red-500'}`}>{`${renderNumber(changeInPercentage)}%`}</span>
          </div>
        </div>
      </div>
      <div className="w-[100%] h-full mt-5">
        {/* {loading === loadingStates.LOADING && (
        <div className="h-full">
          <FlashLoader />
        </div>
        )}
        {loading === loadingStates.FINISHED
       && ( */}
        {data && (
          <ResponsiveContainer width="99%" height="99.59%">
            <LineChart
              data={data}
              margin={{
                top: 37,
                bottom: 10,
                left: 20,
                right: 20,
              }}
            >
              {/* <defs>
              <linearGradient id="fill" x1="139.5" y1="46.5" x2="139.5" y2="60"
               gradientUnits="userSpaceOnUse">
                <stop stopColor="#4ECA2C" />
                <stop offset="0.399452" stopColor="#F9A61A" />
                <stop offset="0.997282" stopColor="#ED2D27" />
              </linearGradient>
            </defs> */}
              <defs>
                <linearGradient id={chartId} x1="0" y1="0" x2="0" y2="1">
                  <stop offset={off} stopColor="green" />
                  <stop offset={off} stopColor="red" />
                </linearGradient>
              </defs>
              {/* <Tooltip
                content={() => null}
                cursor={<CustomizedCursor data={data} height={height} />}
              /> */}
              <Tooltip content={<CustomTooltip />} />
              <XAxis
                dataKey="date"
                tickLine={false}
                tickFormatter={DateFormatter}
                tickSize={12}
                interval={days === '30 Days' ? 5 : 'preserveStartEnd'}
                style={{ fontSize: 10 }}
                axisLine={false}
                stroke="#A3AED0"
                padding={{ left: 10, right: 5 }}
              />
              <ReferenceLine y={0} stroke="#E5F4FD" label={{ value: '0', position: 'left', fill: '#A3AED0' }} />
              <Line
                type="monotone"
                dataKey="value"
                stroke={`url(#${chartId})`}
                strokeWidth={3}
                // activeDot={renderActiveDot}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
        {/* )} */}
      </div>
    </div>
  );
}
